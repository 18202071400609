<script setup>
import { ref } from 'vue'
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import animationData from "@/components/widgets/lupuorrc.json";
import Lottie from "@/components/widgets/lottie.vue";
import { SimpleBar } from "simplebar-vue3";
import store from "@/state/store"; 

import i18n from "../../i18n";

const { t } = i18n.global;

const FaturaAdresleri = ref(store.getters['user/getFaturaAdresleri'])
const SevkiyatAdresleri = ref(store.getters['user/getSevkiyatAdresleri'])

const title = t("teklif-iste")
const items= [
        {
          text: "Elektro Portal",
          href: "/",
        },
        {
          text: t("teklif-iste"),
          active: true,
        },
      ]

const BankaBilgileri = [
  {
    'slug': 'isbank',
    'name': 'İş Bankası',
    'sube': 'Yalova',
    'subeNo': '2500',
    'hesapNo': '869665',
    'iban' : 'TR82 0006 4000 0012 5000 869 665'
  },
  {
    'slug': 'yapikredi',
    'name': 'Yapı Kredi Bankası',
    'sube': 'Yalova',
    'subeNo': '103',
    'hesapNo': '61778190',
    'iban' : 'TR24 0006 7010 0000 0061 7781 90'
  },
  {
    'slug': 'garanti',
    'name': 'Garanti Bankası',
    'sube': 'Yalova',
    'subeNo': '1265',
    'hesapNo': '6200653',
    'iban' : 'TR16 0006 2001 2650 0006 2006 53'
  },
  {
    'slug': 'ziraat',
    'name': 'Ziraat Bankası',
    'sube': 'Yalova',
    'subeNo': '404',
    'hesapNo': '31244852',
    'iban' : 'TR06 0001 0004 0431 2448 5250 01'
  },
  {
    'slug': 'vakifbank',
    'name': 'Vakıfbank',
    'sube': 'Yalova',
    'subeNo': '103',
    'hesapNo': '00782983',
    'iban' : 'TR24 0001 5001 5800 7300 7829 83'
  },
  {
    'slug': 'qnbfinansbank',
    'name': 'QNB Finansbank',
    'sube': 'Yalova',
    'subeNo': '770',
    'hesapNo': '29946579',
    'iban' : 'TR68 0011 1000 0000 0029 9465 79'
  },
  {
    'slug': 'vakifkalitim',
    'name': 'Vakıf Katılım Bankası',
    'sube': 'Yalova',
    'subeNo': '210',
    'hesapNo': '141403-1',
    'iban' : 'TR15 0021 0000 0001 4140 3000 01'
  },
]

const KrediKarti = [
  {
    'slug' : 'bonus',
    'name' : 'Bonus',
    'taksit' : [2,3,6]
  },
  {
    'slug' : 'axess',
    'name' : 'Axess',
    'taksit' : [3,6,9]
  },
  {
    'slug' : 'bankkartcombo',
    'name' : 'Bankkart Combo',
    'taksit' : [2,3,4,5,6]
  },
  {
    'slug' : 'world',
    'name' : 'World',
    'taksit' : [5,6,7]
  },
  {
    'slug' : 'maximum',
    'name' : 'Maximum',
    'taksit' : [2,4,5]
  },
  {
    'slug' : 'qnbfinans',
    'name' : 'QNB Finansbank',
    'taksit' : [3,5]
  }
]

const Step = ref(0);
const defaultOptions= { animationData: animationData }

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body checkout-tab">
            <form action="#">
              <div class="step-arrow-nav mt-n3 mx-n3 mb-3">
                <ul
                  class="nav nav-pills nav-justified custom-nav"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link fs-15 p-3"
                      :class="[Step == 0 ? 'active' : 'done']"
                      type="button"
                    >
                      <i
                        class="
                          ri-user-2-line
                          fs-16
                          p-2
                          bg-soft-primary
                          text-primary
                          rounded-circle
                          align-middle
                          me-2
                        "
                      ></i>
                      {{ $t("Fatura Bilgileri") }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link fs-15 p-3"
                      :class="[Step == 1 ? 'active' : '', Step > 1 ? 'done' : ''  ]"
                      type="button"
                    >
                      <i
                        class="
                          ri-truck-line
                          fs-16
                          p-2
                          bg-soft-primary
                          text-primary
                          rounded-circle
                          align-middle
                          me-2
                        "
                      ></i>
                      {{ $t("Nakliye Bilgisi") }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link fs-15 p-3"  
                      :class="[Step == 2 ? 'active' : '', Step > 2 ? 'done' : ''  ]"
                      type="button"
                    >
                      <i
                        class="
                          ri-bank-card-line
                          fs-16
                          p-2
                          bg-soft-primary
                          text-primary
                          rounded-circle
                          align-middle
                          me-2
                        "
                      ></i>
                      {{ $t("Ödeme Bilgisi") }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link fs-15 p-3"
                      :class="[Step == 3 ? 'done' : ''  ]"
                      type="button"
                    >
                      <i
                        class="
                          ri-checkbox-circle-line
                          fs-16
                          p-2
                          bg-soft-primary
                          text-primary
                          rounded-circle
                          align-middle
                          me-2
                        "
                      ></i
                      >{{ $t("Tamamlandı") }}
                    </button>
                  </li>
                </ul>
              </div>

              <div class="tab-content">
                <div
                  class="tab-pane fade"
                  :class="[Step == 0 ? 'active show' : '']"
                  id="pills-bill-info"
                  role="tabpanel"
                  aria-labelledby="pills-bill-info-tab"
                >
                  <div>
                    <h5 class="mb-1">{{ $t("Fatura Bilgileri") }}</h5>
                    <p class="text-muted mb-4">
                      {{ $t("Lütfen aşağıdaki tüm bilgileri doldurun") }}
                    </p>
                  </div>

                  <div class="mt-4">
                    <div class="d-flex align-items-center mb-2">
                      <div class="flex-grow-1">
                        <h5 class="fs-15 mb-0">{{ $t("Kayıtlı Fatura Bilgileri") }}</h5>
                      </div>
                      <div class="flex-shrink-0">
                        <!-- Button trigger modal -->
                        <button
                          type="button"
                          class="btn btn-sm btn-success mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#addAddressModal"
                        >
                          {{ $t("Fatura Bilgisi Ekle") }}
                        </button>
                      </div>
                    </div>
                    <div class="row gy-3">
                      <div class="col-lg-4 col-sm-6" v-for="fatura in FaturaAdresleri" :key="fatura.id">
                        <div class="form-check card-radio">
                          <input
                            :id="'faturaAddress'+fatura.id"
                            name="faturaAddress"
                            type="radio"
                            class="form-check-input"
                            checked
                          />
                          <label
                            class="form-check-label"
                            :for="'faturaAddress'+fatura.id"
                          >
                            <span
                              class="
                                mb-4
                                fw-bold
                                d-block
                                text-muted text-uppercase
                              "
                              >{{ fatura.baslik }}</span
                            >

                            <span class="fs-15 mb-2">{{ fatura.unvan }}</span>
                            <span
                              class="
                                text-muted
                                fw-normal
                                text-wrap
                                mb-1
                                d-block
                              "
                              >{{ fatura.adres }} {{ fatura.ilce }} / {{ fatura.il }} 
                            </span>
                            <span class="text-muted fw-normal d-block"
                              >VD: {{ fatura.vergidairesi }} / {{ fatura.vergino }}  </span
                            >
                          </label>
                        </div>
                        <div
                          class="
                            d-flex
                            flex-wrap
                            p-2
                            py-1
                            bg-light
                            rounded-bottom
                            border
                            mt-n1
                          "
                        >
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="selectFatura = fatura.id"
                              data-bs-toggle="modal" data-bs-target="#faturaModal"
                              ><i
                                class="
                                  ri-pencil-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Düzenle") }}</a
                            >
                          </div>
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="removeFatura(fatura.id)"
                              ><i
                                class="
                                  ri-delete-bin-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Sil") }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                    <div class="d-flex align-items-start gap-3 mt-3">
                      <button
                        type="button"
                        class="btn btn-primary btn-label right ms-auto nexttab"
                        data-nexttab="pills-bill-address-tab"
                        @click="Step=1"
                      >
                        <i
                          class="
                            ri-truck-line
                            label-icon
                            align-middle
                            fs-16
                            ms-2
                          "
                        ></i
                        >{{ $t("Nakliyeye Devam Et") }}
                      </button>
                    </div>
                  </div>  
                <!-- end tab pane -->

                <div
                  class="tab-pane fade"
                  :class="[Step == 1 ? 'active show' : '']"
                  id="pills-bill-address"
                  role="tabpanel"
                  aria-labelledby="pills-bill-address-tab"
                >
                  <div>
                    <h5 class="mb-1">{{ $t("Nakliye Bilgisi") }}</h5>
                    <p class="text-muted mb-4">
                      {{ $t("Lütfen aşağıdaki tüm bilgileri doldurun") }}
                    </p>
                  </div>

                  <div class="mt-4">
                    <div class="d-flex align-items-center mb-2">
                      <div class="flex-grow-1">
                        <h5 class="fs-15 mb-0">{{ $t("Kayıtlı Adresler") }}</h5>
                      </div>
                      <div class="flex-shrink-0">
                        <!-- Button trigger modal -->
                        <button
                          type="button"
                          class="btn btn-sm btn-success mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#addAddressModal"
                        >
                          {{ $t("Adres Ekle") }}
                        </button>
                      </div>
                    </div>
                    <div class="row gy-3">
                      <div class="col-lg-4 col-sm-6" v-for="adres in SevkiyatAdresleri" :key="adres.id">
                        <div class="form-check card-radio">
                          <input
                            :id="'adresAddress'+adres.id"
                            name="adresAddress"
                            type="radio"
                            class="form-check-input"
                            checked
                          />
                          <label
                            class="form-check-label"
                            :for="'adresAddress'+adres.id"
                          >
                            <span
                              class="
                                mb-4
                                fw-bold
                                d-block
                                text-muted text-uppercase
                              "
                              >{{ adres.baslik }}</span
                            >

                            <span class="fs-15 mb-2">{{ adres.adres }} {{ adres.ilce }} / {{  adres.il }}</span>
                            <span
                              class="
                                text-muted
                                fw-normal
                                text-wrap
                                mb-1
                                d-block
                              "
                              >{{ adres.adres }} {{ adres.ilce }} / {{  adres.il }}
                            </span>
                            <span class="text-muted fw-normal d-block"
                              >VD: YALOVA / 9060055796  </span
                            >
                          </label>
                        </div>
                        <div
                          class="
                            d-flex
                            flex-wrap
                            p-2
                            py-1
                            bg-light
                            rounded-bottom
                            border
                            mt-n1
                          "
                        >
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="selectAdres = adres.id"
                              data-bs-toggle="modal" data-bs-target="#adressModal"
                              ><i
                                class="
                                  ri-pencil-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Düzenle") }}</a
                            >
                          </div>
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="removeAdres(adres.id)"
                              ><i
                                class="
                                  ri-delete-bin-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Sil") }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="fs-15 mb-3">{{ $t("Nakliye Yöntemi") }}</h5>

                      <div class="row g-4">
                        <div class="col-lg-6">
                          <div class="form-check card-radio">
                            <input
                              id="shippingMethod01"
                              name="shippingMethod"
                              type="radio"
                              class="form-check-input"
                              checked
                            />
                            <label
                              class="form-check-label"
                              for="shippingMethod01"
                            >
                              <span
                                class="
                                  fs-20
                                  float-end
                                  mt-2
                                  text-wrap
                                  d-block
                                  fw-bold
                                "
                                >{{ $t("Ücretsiz") }}</span
                              >
                              <span class="fs-16 mb-1 text-wrap d-block"
                                >{{ $t("Kendim teslim almak istiyorum.") }}</span
                              >
                              <span
                                class="text-muted fw-normal text-wrap d-block"
                                >{{ $t("Dilediğiniz gün ve saatte teslim alabilirsiniz.") }}</span
                              >
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-check card-radio">
                            <input
                              id="shippingMethod02"
                              name="shippingMethod"
                              type="radio"
                              class="form-check-input"
                              checked
                            />
                            <label
                              class="form-check-label"
                              for="shippingMethod02"
                            >
                              <span
                                class="
                                  fs-20
                                  float-end
                                  mt-2
                                  text-wrap
                                  d-block
                                  fw-bold
                                "
                                ></span
                              >
                              <span class="fs-16 mb-1 text-wrap d-block"
                                >{{ $t("Tarafıma kargo ile gönderilmesini istiyorum.") }}</span
                              >
                              <span
                                class="text-muted fw-normal text-wrap d-block"
                                >{{ $t("Tutar hesaplanıp teklifte size iletilecektir.") }}</span
                              >
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button
                      type="button"
                      class="btn btn-light btn-label previestab"
                      data-previous="pills-bill-info-tab"
                      @click="Step = 0"
                    >
                      <i
                        class="
                          ri-arrow-left-line
                          label-icon
                          align-middle
                          fs-16
                          me-2
                        "
                      ></i
                      >{{ $t("Fatura Bilgilerine Geri Dön") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-label right ms-auto nexttab"
                      data-nexttab="pills-payment-tab"
                      @click="Step = 2"
                    >
                      <i
                        class="
                          ri-bank-card-line
                          label-icon
                          align-middle
                          fs-16
                          ms-2
                        "
                      ></i
                      >{{ $t("Ödemeye Devam Et") }}
                    </button>
                  </div>
                </div>
                <!-- end tab pane -->

                <div
                  class="tab-pane fade"
                  :class="[Step == 2 ? 'active show' : '']"
                  id="pills-payment"
                  role="tabpanel"
                  aria-labelledby="pills-payment-tab"
                >
                  <div>
                    <h5 class="mb-1">{{ $t("Ödeme Bilgisi") }}</h5>
                    <p class="text-muted mb-4">
                      {{ $t("Lütfen ödeme yöntemi seçin.") }}
                    </p>
                  </div>

                  <div class="row g-4">
                    <div class="col-lg-4 col-sm-6">
                      <div
                        data-bs-toggle="collapse"
                        data-bs-target="#kkmethodCollapse"
                        aria-expanded="false"
                        aria-controls="paymentAccordion">
                        <div class="form-check card-radio">
                          <input
                            id="paymentMethod01"
                            name="paymentMethod"
                            type="radio"
                            class="form-check-input"
                          />
                          <label class="form-check-label" for="paymentMethod01">
                            <span class="fs-16 text-muted me-2"
                              ><i class="ri-bank-card-line align-bottom"></i
                            ></span>
                            <span class="fs-14 text-wrap">{{ $t("Banka/Kredi Kartı") }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                      <div
                        data-bs-toggle="collapse"
                        data-bs-target="#paymentmethodCollapse"
                        aria-expanded="false"
                        aria-controls="paymentAccordion">
                        <div class="form-check card-radio">
                          <input
                            id="paymentMethod02"
                            name="paymentMethod"
                            type="radio"
                            class="form-check-input"
                          />
                          <label class="form-check-label" for="paymentMethod02">
                            <span class="fs-16 text-muted me-2"
                              ><i class="ri-arrow-left-right-line align-bottom"></i
                            ></span>
                            <span class="fs-14 text-wrap">{{ $t("Havale/EFT") }}</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                      <div
                        data-bs-toggle="collapse"
                        data-bs-target="#cekCollapse"
                        aria-expanded="false"
                        aria-controls="paymentAccordion">
                        <div class="form-check card-radio">
                          <input
                            id="paymentMethod03"
                            name="paymentMethod"
                            type="radio"
                            class="form-check-input"
                          />
                          <label class="form-check-label" for="paymentMethod03">
                            <span class="fs-16 text-muted me-2"
                              ><i
                                class="ri-coupon-3-line align-bottom"
                              ></i
                            ></span>
                            <span class="fs-14 text-wrap"
                              >{{ $t("Çek") }}</span
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion" id="paymentAccordion">
                  <div class="accordion-collapse collapse" id="paymentmethodCollapse" data-bs-parent="#paymentAccordion">
                    <div class="card p-4 border shadow-none mb-0 mt-4">
                      <div class="table-responsive">
                        <table class="table align-middle mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">{{ $t("Banka") }}</th>
                                    <th scope="col">{{ $t("Banka Bilgileri") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="banka in BankaBilgileri" :key="banka.slug">
                                    <td>
                                        <img :src="require('@/assets/images/banka/' + banka.slug + '.png')" alt="" style="width: 100px;" />
                                    </td>
                                    <td>
                                        {{ banka.name }}
                                    </td>
                                    <td>{{ banka.sube }} / {{ banka.subeNo}} - {{ banka.hesapNo}}<br /><code>{{ banka.iban }}</code></td>
                                </tr>
                            </tbody>  
                        </table>
                        <!-- end table -->
                    </div>
                    <!-- end table responsive -->
                    </div>
                  </div>

                  
                  <div class="accordion-collapse collapse" id="kkmethodCollapse" data-bs-parent="#paymentAccordion">
                    <div class="card p-4 border shadow-none mb-0 mt-4">
                      <div class="table-responsive">
                        <table class="table align-middle mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col" style="width: 10%">{{ $t("Taksit Sayısı") }}</th>
                                    <th scope="col" v-for="kk in KrediKarti" :key="kk.slug">
                                        <img :src="require('@/assets/images/banka/' + kk.slug + '.png')" alt="" style="width: 100px;" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="taksitSayisi in [2,3,4,5,6,7,8,9]" :key="taksitSayisi">
                                    <td>
                                      {{taksitSayisi}}
                                    </td>
                                    <td class="text-center" v-for="kk in KrediKarti" :key="kk.slug">
                                      <i :class="(kk.taksit.indexOf(taksitSayisi) !=  -1) ? ['ri-checkbox-circle-line', 'text-success', 'fw-bold'] : ['ri-close-circle-line', 'text-danger']"></i>
                                    </td>
                                </tr>
                            </tbody>  
                        </table>
                        <!-- end table -->
                    </div>
                    <!-- end table responsive -->
                    </div>
                  </div>
                  <div class="accordion-collapse collapse" id="cekCollapse" data-bs-parent="#paymentAccordion"></div>

                  </div>

                  <div class="d-flex align-items-start gap-3 mt-4">
                    <button
                      type="button"
                      class="btn btn-light btn-label previestab"
                      data-previous="pills-bill-address-tab"
                      @click="Step = 1"
                    >
                      <i
                        class="
                          ri-arrow-left-line
                          label-icon
                          align-middle
                          fs-16
                          me-2
                        "
                      ></i
                      >{{ $t("Nakliyeye Geri Dön") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-label right ms-auto nexttab"
                      data-nexttab="pills-finish-tab"
                      @click="Step = 3"
                    >
                      <i
                        class="
                          ri-shopping-basket-line
                          label-icon
                          align-middle
                          fs-16
                          ms-2
                        "
                      ></i
                      >{{ $t("teklif-iste") }}  
                    </button>
                  </div>
                </div>
                <!-- end tab pane -->

                <div
                  class="tab-pane fade"
                  :class="[Step == 3 ? 'active show' : '']"
                  id="pills-finish"
                  role="tabpanel"
                  aria-labelledby="pills-finish-tab"
                >
                  <div class="text-center py-5">
                    <div class="mb-4">
                      <lottie
                        colors="primary:#67b173,secondary:#3d78e3"
                        :options="defaultOptions"
                        :height="120"
                        :width="120"
                      />
                    </div>
                    <h5>{{ $t("Teşekkürler ! Teklifiniz Tamamlandı!") }}</h5>
                    <p class="text-muted">
                      {{ $t("Teklifinizin ayrıntılarını içeren bir sipariş onay e-postası alacaksınız.") }}
                    </p>

                    <h3 class="fw-semibold">
                      {{ $t("Teklif Nu.") }}:
                      <router-link
                        to="/ "
                        class="text-decoration-underline"
                        >TZ2451</router-link
                      >
                    </h3>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </form>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xl-4">
        <div class="card">
          <div class="card-header">
            <div class="d-flex">
              <div class="flex-grow-1">
                <h5 class="card-title mb-0">{{ $t("Teklif Özeti") }}</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive table-card">
              <table class="table table-borderless align-middle mb-0">
                <thead class="table-light text-muted">
                  <tr>
                    <th style="width: 90px" scope="col">{{ $t("Ürün") }}</th>
                    <th scope="col">{{ $t("Ürün Bilgisi") }}</th>
                    <th scope="col" class="text-end">{{ $t("Fiyat") }}</th>
                  </tr>
                </thead>
                </table>
                <SimpleBar data-simplebar style="max-height: 500px; width: auto "> 
                <table class="table table-borderless align-middle mb-0">
                <tbody>
                  <tr v-for="product in $store.getters['cart/getCart']" :key="product.id">
                    <td>
                      <div class="avatar-md bg-light rounded p-1">
                        <img
                          :src="product.image"
                          alt=""
                          class="img-fluid d-block"
                        />
                      </div>
                    </td>
                    <td>
                      <h5 class="fs-15">
                        <router-link
                          to="/"
                          class="text-dark"
                          >{{ product.name }}</router-link
                        >
                      </h5>
                      <p class="text-muted mb-0">{{
                          $filters.currency(
                            product.price
                          )
                        }} x {{ product.value}}</p>
                    </td>
                    <td class="text-end" style="white-space: nowrap;">{{
                          $filters.currency(
                            product.price * product.value
                          )
                        }}</td>
                  </tr>
                  </tbody>
                  </table>
                </SimpleBar> 
                <table class="table table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td class="fw-semibold" colspan="2">{{ $t("Ara Toplam") }} :</td>
                    <td class="fw-semibold text-end" style="white-space: nowrap;">{{
                          $filters.currency(
                            $store.getters["cart/getCartTotalPrice"]
                          )
                        }}</td>
                  </tr>
                  <tr class="table-active">
                    <th colspan="2">{{ $t("Toplam") }} :</th>
                    <td class="text-end" style="white-space: nowrap;">
                      <span class="fw-semibold">{{
                          $filters.currency(
                            $store.getters["cart/getCartTotalPrice"]
                          )
                        }}</span>
                    </td>
                  </tr>
                  </tbody>
              </table>   
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
